import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import IntroArea from "@components/introarea/layout-1";
import PartnerArea from "@containers/partner/layout-04";
import BoxSection from "@components/BoxSection/layout-two/layout-1";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import HowWeWork from "@containers/global/how-we-work";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/translation-quote-form";

const TravelHospitalityPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Travel & Hospitality - reach new global travellers"
      description="Experience is everything to travelers. Convey your message clearly in every language your audience speaks, on every device and channel." 
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["travel-page-header-data"]} />
        <QuoteForm />
        <PartnerArea data={content["travel-hospitality-logo"]} />
        <FeatureGrid data={content["travel-hospitality-features"]} />
        <IntroArea layout={2} data={content["travel-intro"]} />
        <BoxSection layout={1} data={content["travel-localize-body"]} />
        <HowWeWork layout={2} data={content["howwework"]} />
        <CaseStudy data={caseStudiesData} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query travelHospitalityPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "travel-hospitality" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

TravelHospitalityPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TravelHospitalityPage;
